<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <v-row>
        <!--
        <v-col
          cols="6"
        >
          <v-dialog
            ref="orderDialog"
            v-model="modal"
            class="date-picker"
            :return-value.sync="orderDate"
            :width="datePickerWidth"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="orderDate"
                label="납품기준일"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="orderDate"
              locale="ko-ko"
              @input="onOrderDateInput"
            />
          </v-dialog>
        </v-col>
        -->
        <v-col
          cols="12"
          class="mb-2"
          align="right"
        >
          <v-btn
            depressed
            color="primary"
            @click="onClickInputEnd"
          >
            입고 완료
          </v-btn>
        </v-col>
      </v-row>
      <dx-data-grid
        ref="refOrderDetailGrid"
        :data-source="deliveryDetailListInfo"
        :show-borders="true"
        :show-row-lines="false"
        :show-column-lines="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :row-alternation-enabled="true"
        :height="gridHeight"
        :focused-row-enabled="true"
        key-expr="발주번호"
        :focused-row-key.sync="focusedRowKeyOrder"
        @focused-row-changed="onSelectionChangedDetail"
        @toolbar-preparing="onToolbarPreparingDetail"
        @cell-prepared="onCellPrepared"
      >
        <DxSearchPanel
          :visible="false"
          :width="120"
          placeholder="검색..."
        />
        <DxFilterRow
          :visible="false"
          apply-filter="auto"
        />
        <DxColumn
          :allow-editing="false"
          caption="NO"
          data-field="sitelogisticstaskid"
          sort-order="asc"
          :min-width="50"
        />
        <DxColumn
          :allow-editing="false"
          caption="품번"
          data-field="품번"
          :min-width="114"
        />
        <DxColumn
          :allow-editing="false"
          caption="주문"
          data-field="오더수량"
          :min-width="64"
        />
        <DxColumn
          :allow-editing="false"
          caption="입고"
          data-field="입고수량"
          :min-width="64"
        />
        <DxColumn
          width="50"
          :buttons="editButtons"
          type="buttons"
        />
        <DxSummary>
          <DxTotalItem
            value-format="#,##0"
            show-in-column="품번"
            display-format="{0} (건)"
          />
        </DxSummary>
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtual"
          show-scrollbar="always"
          :use-native="true"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
      <!--
      <v-row>
        <v-col
          class="pt-6"
          cols="12"
        >
          <v-btn
            width="100%"
            color="error"
            @click="onClickInputEnd"
          >
            입고완료
          </v-btn>
        </v-col>
      </v-row>
      -->
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
    <div
      class="left-draw-wrap"
    >
      <v-app-bar
        id="left-draw-app-bar"
        dark
        height="50px"
      >
        <v-icon
          large
          @click="onClickBefore"
        >
          mdi-chevron-left
        </v-icon>
        <v-toolbar-title style="margin-left:10px;">
          구매품 입고
        </v-toolbar-title>
      </v-app-bar>
      <div
        v-if="selectedRowDetail"
        class="left-draw-content"
      >
        <v-container>
          <div class="content-input-wrap">
            <div
              v-if="$store.getters.getMobileModel !== 'PDA' && showQrScaner==='block'"
              class="qrcode-wrap"
              :style="{display: showQrScaner}"
            >
              <QrCodeScanComponent @scan-result="onPCodeScan" />
            </div>
            <v-row>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="selectedRowDetail.발주번호"
                  label="생산지시번호"
                  :readonly="true"
                  filled
                  autocomplete="off"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="selectedRowDetail.오더수량"
                  label="오더수량"
                  :readonly="true"
                  filled
                  autocomplete="off"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="selectedRowDetail.품번"
              label="품번"
              :readonly="true"
              filled
              autocomplete="off"
            />
            <v-text-field
              v-model="selectedRowDetail.품명"
              label="품명"
              :readonly="true"
              filled
              autocomplete="off"
            />
            <v-row>
              <v-col
                class="pb-0"
                :class="{'pr-0': $store.getters.getMobileModel !== 'PDA' }"
                :cols="$store.getters.getMobileModel !== 'PDA' ? 5 : 6"
              >
                <v-text-field
                  id="input-qrkey"
                  v-model="qrKey"
                  label="파레트번호"
                  clearable
                  autocomplete="off"
                  placeholder="QR 스캔"
                  @keyup.enter.prevent="onEnterPcode"
                />
              </v-col>
              <v-col
                v-if="$store.getters.getMobileModel !== 'PDA'"
                class="pb-0 pt-7 pl-0"
                cols="1"
              >
                <v-icon
                  @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
                >
                  mdi-camera
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="pb-0 pt-0"
                cols="6"
              >
                <v-text-field
                  id="input-lot-no"
                  v-model="inputLotNo"
                  label="Lot번호"
                  clearable
                  autocomplete="off"
                  @keyup.enter.prevent="onEnterLotNo"
                />
              </v-col>
              <v-col
                class="pb-1 pt-0"
                cols="6"
              >
                <v-text-field
                  id="input-edate"
                  v-model="effectiveDate"
                  v-mask="'####-##-##'"
                  label="유효기간"
                  placeholder="####-##-##"
                  clearable
                  autocomplete="off"
                  @keyup.enter.prevent="onEnterDate"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="pb-0 pt-0"
                cols="6"
              >
                <v-autocomplete
                  id="input-location"
                  v-model="selectedLocation"
                  :items="locationLookup"
                  item-text="장소명"
                  item-value="장소코드"
                  placeholder="입고저장장소"
                  label="입고저장장소"
                  @input="onEnterLocation"
                />
              </v-col>
              <v-col
                class="pb-0 pt-0"
                cols="6"
              >
                <v-text-field
                  id="input-qty"
                  v-model="inputQty"
                  label="입고수량"
                  type="number"
                  clearable
                  autocomplete="off"
                  @keyup.enter.prevent="onEnterQty"
                />
              </v-col>
            </v-row>
            <v-row
              class="mt-4"
            >
              <v-col
                cols="12"
              >
                <v-btn
                  width="100%"
                  color="primary"
                  @click="onEnterQty"
                >
                  입고 등록
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import DxDataGrid, { DxScrolling, DxColumn, DxPaging, DxSorting, DxSearchPanel, DxFilterRow, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'
import axios from 'axios'
import HttpService from '../../share/service/HttpService'

export default {
  name: 'PurchaseDeliveryByOrder',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxFilterRow,
    DxSummary,
    DxTotalItem,
    QrCodeScanComponent
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      systemParam: null,
      gridHeight: null,
      focusedRowKeyOrder: null,
      effectiveDate: null,
      modal: false,
      qrKey: null,
      inputQty: null,
      inputLotNo: null,
      deleverydatetime: null,
      htmlWdth: 800,
      editButtons: [
        {
          hint: '삭제',
          icon: 'trash',
          visible: 'true',
          onClick: this.onRowRemoving
        }],
      // qrcode scan
      showQrScaner: 'none',
      orderDate: null,
      // detail
      deliveryDetailListInfo: [],
      selectedRowDetail: [],
      // location
      selectedLocation: null,
      locationLookup: []

    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.orderDate = AppLib.getToday()
    this.datePickerWidth = this.getDatePickerWidth()
    this.systemParam = this.$store.getters.getSysParam
    this.locationLookup = this.$store.getters.getLocation
    this.selectedLocation = this.locationLookup[0].장소코드
    // const where = this.$_.findWhere(this.systemParam, { key: '제품유효기간' })
    // this.effectiveDate = AppLib.calcDateFromToday(parseInt(where.value))
    this.refreshDelivery()
  },
  mounted () {
    this.gridHeight = (this.$Q('html').height() - 120)
    this.htmlWdth = this.$Q('html').width()
    window.addEventListener('resize', this.handleResize)
    this.$Q('.left-draw-wrap').css('left', `${this.htmlWdth}px`)
    this.$Q('.left-draw-wrap').css('height', `${this.$Q('html').height()}px`)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    getDatePickerWidth () {
      return `${AppLib.getDatePickerWidth(this.$store)}px`
    },
    handleResize (event) {
      this.htmlWdth = this.$Q('html').width()
      if (this.$Q('.left-draw-wrap').css('left') !== '0px') {
        this.$Q('.left-draw-wrap').css('left', `${this.htmlWdth}px`)
      }
      this.$Q('.left-draw-wrap').css('height', `${this.$Q('html').height()}px`)
      this.gridHeight = (this.$Q('html').height() - 120)
    },
    async addInventory () {
      this.deleveryDateTime = AppLib.getNow()
      let stockSameCode = null
      if (this.effectiveDate !== null && this.inputLotNo !== null) {
        stockSameCode = `${this.effectiveDate.substring(0, 4)}${this.effectiveDate.substring(5, 7)}${this.effectiveDate.substring(8, 10)}_${this.inputLotNo}`
        stockSameCode = stockSameCode.toUpperCase()
      }
      const param = {
        발생일자: this.deleveryDateTime,
        입출고구분: ConstDef.자재입고,
        입고키: this.qrKey,
        품번: this.selectedRowDetail.품번,
        품명: this.selectedRowDetail.품명,
        수량: parseInt(this.inputQty),
        거래처명: this.selectedRowDetail.거래처명,
        거래처코드: this.selectedRowDetail.거래처코드,
        발주번호: this.selectedRowDetail.발주번호,
        로트번호: this.inputLotNo,
        품목유형: ConstDef.완제품,
        유효일: this.effectiveDate,
        동종재고코드: stockSameCode,
        장소위치코드: this.selectedLocation
      }
      this.isLoading = true
      const regInputProc = this.$_sp.runNoEncodeFindSqlProc('창고수불', { 입고키: this.qrKey, 품번: this.selectedRowDetail.품번 })
      const procArray = []
      procArray.push(regInputProc)
      await axios.all(procArray)
        .then(axios.spread((...reponse) => {
          if (this.$_sp.MakeModel(reponse[0]).length > 0) {
            this.isLoading = false
          }
        }))
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })

      if (this.isLoading === false) {
        this.$snotify.error('해당 폼번/파레트 번호는 이미 입고 등록이 되어 있습니다.')
        this.initInput()
        return
      }
      const updateParam = {
        발주번호: this.selectedRowDetail.발주번호,
        입고등록: true
      }
      const order = this.$_sp.runUpdateSqlProc('구매발주서OUT', ['발주번호'], null, [updateParam])
      const receipt = this.$_sp.runInsertSqlProc('창고수불', null, [param])
      axios.all([receipt, order])
        .then(axios.spread((...reponse) => {
          this.selectedRowDetail.입고수량 = this.inputQty
          this.selectedRowDetail.입고등록 = true
          this.isLoading = false
          this.$snotify.info('저장 되었습니다.')
          this.$refs.refOrderDetailGrid.instance.refresh()
          this.initInput()
          this.onClickBefore()
        }))
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    initInput () {
      this.qrKey = null
      this.inputQty = null
      this.effectiveDate = null
      this.inputLotNo = null
      setTimeout(() => {
        this.$Q('#input-qrkey').focus()
      }, 10)
    },
    onCellPrepared (e) {
      if (e.rowType === 'data' && e.column.type !== undefined) {
        if (e.row.data.입고등록 === false || e.row.data.taskInputEnd) {
          this.$Q(e.cellElement).hide()
        }
      }
    },
    onClickOrder () {
      this.refreshDelivery('ALL')
    },
    onClickInputEnd (e) {
      if (this.deliveryDetailListInfo.length === 0) return
      this.$snotify.confirm('입고 완료 처리 하시겠습니까?', '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '완료',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              this.inputCompleted()
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    inputCompleted () {
      const inList = this.$_.where(this.deliveryDetailListInfo, { 입고등록: true, taskInputEnd: false })
      console.log(inList)
      const taskId = this.$_.groupBy(inList, 'sitelogisticstaskid')
      console.log(taskId)
      const sendTackId = []
      let noCompleted = ''
      for (const value of Object.keys(taskId)) {
        console.log(value)
        const array = this.$_.where(this.deliveryDetailListInfo, { sitelogisticstaskid: value })
        console.log(array)
        for (let i = 0; i < array.length; i++) {
          console.log(array[i].입고등록)
          if (array[i].입고등록 === false) {
            noCompleted += `${array[i].sitelogisticstaskid} `
            break
          }
        }
        for (let i = 0; i < array.length; i++) {
          const temp = {
            발주번호: array[i].발주번호,
            taskInputEnd: true
          }
          sendTackId.push(temp)
        }
      }

      if (sendTackId.length > 0) {
        if (noCompleted.length > 0) {
          this.$snotify.confirm(`오더번호(${noCompleted})중 입고처리가 되지 않은 품목이 있습니다. 완료 저리 하시겠습니까`, '', {
            timeout: 10000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            buttons: [
              {
                text: '완료',
                action: (toast) => {
                  this.$snotify.remove(toast.id)
                  this.saveInputCompleted(sendTackId)
                }
              },
              {
                text: '취소',
                action: (toast) => { this.$snotify.remove(toast.id) }
              }
            ]
          })
        } else {
          this.saveInputCompleted(sendTackId)
        }
      } else {
        this.$snotify.info('모든 입고항목이 이미 완료 처리 되었습니다.')
      }
    },
    saveInputCompleted (sendTackId) {
      const order = this.$_sp.runUpdateSqlProc('구매발주서OUT', ['발주번호'], null, sendTackId)
      const stock = this.$_sp.runUpdateSqlProc('창고수불', ['발주번호'], null, sendTackId)
      axios.all([order, stock])
        .then(axios.spread((...reponse) => {
          this.$snotify.info('저장 되었습니다.')
          this.refreshDelivery()
          HttpService.reqPost(null, '/updatePurchaseInput')
        }))
    },
    onEnterLocation (e) {
      this.$Q('#input-qty').focus()
    },
    onEnterPcode (e) {
      if (this.qrKey === null || this.qrKey.length === 0) {
        return
      }
      let split = null
      split = AppLib.base64DecodeSubstring(this.qrKey)
      this.qrKey = parseInt(split)
      this.showQrScaner = 'none'
      this.$Q('#input-lot-no').focus()
    },
    onPCodeScan (result) {
      this.qrKey = result
      this.onEnterPcode(null)
    },
    onEnterDate (e) {
      if (!AppLib.validateDate(this.effectiveDate)) {
        this.$snotify.info('잘못된 유효일 형식 입니다.')
        return
      }
      this.$Q('#input-qty').focus()
    },
    onEnterLotNo (e) {
      this.$Q('#input-edate').focus()
    },
    onEnterQty (e) {
      if (this.inputQty === null) return
      if (this.qrKey === null || this.qrKey.length === 0) {
        this.$snotify.error('파레트 번호를 스캔하여 주세요')
        return
      }
      this.$Q('#input-qrkey').focus()
      this.addInventory()
    },
    onRowRemoving (e) {
      if (!e.row.data.입고등록) {
        this.$snotify.error('등록된 입고정보가 없습니다.')
        return
      }
      if (e.row.data.taskInputEnd) {
        this.$snotify.error('현재 품목은 입고처리가 완료 되어 삭제할 수 없습니다.')
        return
      }
      this.$snotify.confirm('삭제 하시겠습니까?', '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '삭제',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              this.onRowRemoved(e.row)
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    onRowRemoved (row) {
      const updateParam = {
        발주번호: row.data.발주번호,
        입고등록: false
      }
      const order = this.$_sp.runUpdateSqlProc('구매발주서OUT', ['발주번호'], null, [updateParam])
      const receipt = this.$_sp.runDeleteSqlProc('창고수불', [{ 발주번호: row.data.발주번호 }])
      axios.all([receipt, order])
        .then(axios.spread((...reponse) => {
          row.data.입고수량 = 0
          row.data.입고등록 = false
          this.focusedRowKeyOrder = null
          this.onClickBefore()
          this.$snotify.info('삭제 되었습니다.')
        }))
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    // 하단 그리드 클릭시 발생하는 이벤트 -> 디테일 내역에서 자재입고를 보여줌
    onSelectionChangedDetail (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        if (e.row.data.입고등록 === false && e.row.data.taskInputEnd) {
          this.$snotify.info('오더종료 처리 되었습니다. 내역은 ByD에서 확인 하세요.')
          return
        }
        if (e.row.data.입고등록 === true && e.row.data.taskInputEnd) {
          this.$snotify.info('입고완료 처리 되었습니다. 내역은 창고 입출 현황에서 조회 가능 합니다.')
          return
        }
        if (e.row.data.입고등록) {
          this.$snotify.info('입고처리 되었습니다. 수정은 삭제후 재 입력하여 주세요.')
          return
        }
        this.selectedRowDetail = e.row.data
        const vm = this
        this.$Q('.left-draw-wrap').animate({
          left: '0'
        }, 800, function () {
          vm.$Q('#left-draw-app-bar').css('position', 'fixed')
          vm.$Q('.left-draw-content').css('padding-top', '50px')
          vm.$Q('#input-qrkey').focus()
          vm.focusedRowKeyOrder = null
          vm.$Q('#input-qrkey').focus()
        })
      }
    },
    onToolbarPreparingDetail (e) {
      /*
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: function () {
            return '<div class="toolbar-label" style="margin-left:4px;"><b>발주 정보</b></div>'
          }
        }
      )
      */
    },
    refreshDelivery (option) {
      this.isLoading = true
      const param = {
        OpTypeStart: '11',
        OpTypeEnd: '12'
      }

      this.$_sp.runNoEncodeFindProc('spFindAllPurchaseOrderByOpType', param)
        .then((data) => {
          this.isLoading = false
          this.deliveryDetailListInfo = this.$_sp.MakeModel(data)
          console.log(this.deliveryDetailListInfo)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onOrderDateInput () {
      this.$refs.orderDialog.save(this.orderDate)
      this.refreshDelivery()
    },
    // left slide view
    onClickBefore () {
      this.refreshDelivery()
      this.$Q('#left-draw-app-bar').css('position', 'static')
      this.$Q('.left-draw-content').css('padding-top', '0px')
      this.$Q('.left-draw-wrap').animate({
        left: `${this.htmlWdth}px`
      }, 500)
    }
  }
}
</script>

<style lang="scss">
  .left-draw-content {
    background-color: black;
  }
</style>
